import axios from "axios";

const TOKEN_KEY = "ClubeViaSushiApp-token";
export const BASE_URL = "https://viasushidelivery.com.br/api"; // "http://localhost:3002" 

export const useAxios = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  const saveToken = (token) => localStorage.setItem(TOKEN_KEY, token);
  const clearToken = () => localStorage.removeItem(TOKEN_KEY);

  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return {
    axios: instance,
    saveToken,
    clearToken,
  };
};
