import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Form, Button } from "semantic-ui-react"
import { useAxios } from "../hooks/useAxios"
import { useAppContext, USUARIO_LOGADO_KEY } from "../hooks/useAppContext"

const LoginPage = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const { axios, saveToken } = useAxios()
    const navigate = useNavigate()
    const {  usuarioLogado, setUsuarioLogado } = useAppContext()

    useEffect(() => {
        if (usuarioLogado && navigate) {
            navigate(`/pedidos`)
        }
    }, [usuarioLogado, navigate])

    const handleLoginFormSubmit = async () => {
        try {
            setLoading(true)
            const response = await axios.post('/login', { email, password })
            const { token } = response.data
            saveToken(token)
            navigate('/pedidos')
            const usuario = {
                email
            }
            setUsuarioLogado(usuario)
            localStorage.setItem(USUARIO_LOGADO_KEY, JSON.stringify(usuario))

        } catch (error) {
            //console.log(error)
            setPassword('')
            alert(error?.response?.data?.mensagem || 'Erro ao tentar o acesso. Favor verificar sua conexão com a internet.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <div style={{ width: '250px', border: '1px solid #ccc', padding: 20}}>
            <Form onSubmit={handleLoginFormSubmit}>
                <Form.Field>
                    <label>Usuário</label>
                    <input value={email} onChange={e => setEmail(e.target.value)} />
                </Form.Field>
                <Form.Field>
                    <label>Senha de acesso</label>
                    <input type='password' value={password} onChange={e => setPassword(e.target.value)}  />
                </Form.Field>
                <Button type='submit'>Entrar</Button>
            </Form>
            <div>
                Para solicitar exclusão de conta ou de dados pessoais por favor envie um e-mail para pablovianelo87@gmail.com. Iremos processar a sua solicitação e enviar resposta via e-mail.
            </div>
        </div>
    )
}

export default LoginPage