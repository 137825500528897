import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import PedidosPage from './pages/PedidosPage';



import 'semantic-ui-css/semantic.min.css'
import LoginPage from './pages/LoginPage';
import { AppContextProvider } from './hooks/useAppContext'
import CombosPage from './pages/CombosPage';

import moment from 'moment-timezone'
moment.tz.setDefault('UTC')


const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/combos",
    element: <CombosPage />,
  },
  {
    path: "/pedidos",
    element: <PedidosPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={router} />
    </AppContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
