import { useState, useEffect } from 'react'
import { Container, Menu, Segment } from 'semantic-ui-react'
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import { useAppContext } from '../hooks/useAppContext';


const LoggedInLayout = ({ children }) => {

  const [activeItem, setActiveItem] = useState('Pedidos')
  const handleItemClick = (e, { name }) => setActiveItem(name)

  const navigate = useNavigate()
  const location = useLocation();
  const { usuarioLogado, deslogar } = useAppContext()

  useEffect(() => {
    if (!usuarioLogado && navigate) {
        navigate(`/`)
    }
}, [usuarioLogado, navigate])

  const MenuPrincipal = () => (
    <Menu inverted>
      <Menu.Item
        name={'Pedidos'}
        active={location.pathname === '/pedidos'}
        onClick={() => {
          navigate('/')
          setActiveItem('Pedidos')
        }}
      />
      <Menu.Item
        name='Combos'
        active={location.pathname === '/combos'}
        onClick={(e, data) => {
          setActiveItem('Combos')
          handleItemClick(e, data);
          navigate(`/combos`)
        }}
      />
      <Menu.Item
        name='Sair'
        active={activeItem === 'Sair'}
        onClick={(e, data) => {
          handleItemClick(e, data);
          deslogar();
        }}
      />
    </Menu>
  )

  return (
    <div>
      <MenuPrincipal />
      <Segment>
        {children}
      </Segment>
    </div>
  )
}

export default LoggedInLayout