import { createContext, useContext, useEffect, useState } from "react";
import { useAxios } from "./useAxios";

export const USUARIO_LOGADO_KEY = 'UsuarioLogado'
const AppContext = createContext({})

export const useAppContext = () => useContext(AppContext)

export const AppContextProvider = ({ children }) => {
    const [usuarioLogado, setUsuarioLogado] = useState(null)
    const { clearToken } = useAxios()

    useEffect(() => {
        const usuarioLogadoStr = localStorage.getItem(USUARIO_LOGADO_KEY)
        setUsuarioLogado(usuarioLogadoStr ? JSON.parse(usuarioLogadoStr) : null)
    }, [])

    const deslogar = () => {
        localStorage.removeItem(USUARIO_LOGADO_KEY)
        setUsuarioLogado(null)
        clearToken();
    }

    return (
        <AppContext.Provider value={{ 
            usuarioLogado, 
            setUsuarioLogado,
            deslogar
        }}>
            {children}
        </AppContext.Provider>
    )
}

