import { Table, Button, Icon } from "semantic-ui-react";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { useAxios } from "../hooks/useAxios";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from "../hooks/useAxios";


const ComboImageUploader = ({ oferta, onChange }) => {

  const { axios } = useAxios();

  const uploadImageFile = (e) => {
    if (e?.target?.files?.length === 1) {
      const file = e.target.files[0]
      const ext = file?.name?.substring(file?.name?.lastIndexOf('.') + 1)
      const filename = `${oferta.id}.${ext}`

      const formData = new FormData()
      formData.append('imagem', file)
      

      axios.post(`/upload-imagem-combo/${filename}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then(() => {
        if (onChange) {
          const uri = `/imagens_ofertas/${filename}`
          onChange(uri)
        }
      }).catch(() => {
        alert('Erro ao tentar realizar o envio da imagem.')
      });
    }
  }

  return (    
    <input type="file" name="imagem" onChange={uploadImageFile}/>
  )
}

const CombosPage = () => {
  const [ofertas, setOfertas] = useState([]);
  const { axios } = useAxios();

  const fetchCombos = () => {
    axios
      .get("/ofertas?trazerInativas=SIM")
      .then((res) => {
        setOfertas(res.data);
      })
      .catch((error) => {
        alert(
          error?.response?.data?.mensagem ||
            "Falha ao tentar recuperar as ofertas. Por favor verifique sua conectividade e tente novamente."
        );
      });
  };

  const toggleEditMode = oferta => {
    atualizarStateOferta({
      ...oferta, 
      editMode: !!!oferta.editMode
    })
  }

  const updateField = (oferta, field, value) => {
    const newObj = { ...oferta }
    newObj[field] = value
    atualizarStateOferta(newObj)
  }

  const atualizarStateOferta = oferta => {
    const index = ofertas.findIndex(el => el.id === oferta.id)
    setOfertas([
      ...ofertas.slice(0, index),
      { ...oferta },
      ...ofertas.slice(index + 1),
    ])
  }

  const novaOferta = () => {
    const newObj = {
      editMode: true
    }
    setOfertas([ newObj, ...ofertas ])
  }

  const saveOferta = oferta => {
    axios
      .post("/ofertas", { oferta })
      .then((res) => {
        toggleEditMode(oferta)
        alert('Oferta salva com sucesso!')
      })
      .catch((error) => {
        alert(
          error?.response?.data?.mensagem ||
            "Falha ao tentar salvar a oferta. Por favor verifique sua conectividade e tente novamente."
        );
      });
  }

  async function handleAtivacao(event, oferta) {
    try {
      await ativarOferta(oferta.id, event.target.checked);
      fetchCombos()
    } catch (error) {}
  }

  const ativarOferta = async (id, isOfertaAtiva) => {
    try {
      const response = await axios.post(`/ofertas/${id}/ativar`, {
        isOfertaAtiva,
      });
    } catch (error) {
      //console.log(error);
      alert(error?.response?.data?.mensagem || 'Falha ao tentar ativar a oferta.');
    }
  };

  useEffect(() => {
    fetchCombos();
  }, []);

  return (
    <LoggedInLayout>
      <h1>Combos</h1>
      <Button primary onClick={novaOferta}>Adicionar</Button>

      <div>
      <Table stackable compact celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ativa</Table.HeaderCell>
            <Table.HeaderCell>Imagem</Table.HeaderCell>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Descrição</Table.HeaderCell>
            <Table.HeaderCell>Quantidade de Entregas</Table.HeaderCell>
            <Table.HeaderCell>De</Table.HeaderCell>
            <Table.HeaderCell>Por</Table.HeaderCell>          
            <Table.HeaderCell>Actions</Table.HeaderCell>              
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {ofertas?.map((oferta) => {
            return (
              <Table.Row key={oferta.id}>
                <Table.Cell>
                <input
                    type="checkbox"
                    checked={oferta.ativa}
                    onChange={(event) => handleAtivacao(event, oferta)}
                  />
                </Table.Cell>                
                <Table.Cell>
                    <img alt='' src={BASE_URL + oferta.image_url} width={100} />
                    {oferta.editMode && <ComboImageUploader oferta={oferta} onChange={uploadedUrl => updateField(oferta, 'image_url', uploadedUrl)} />}
                </Table.Cell>
                <Table.Cell>{oferta.editMode ? (
                  <input value={oferta.nome} onChange={e => updateField(oferta, 'nome', e.target.value)} />
                ) : (
                  <span>{oferta.nome}</span>
                )}</Table.Cell>
                
                <Table.Cell>{oferta.editMode ? (
                  <textarea style={{ width: 200 }} rows={3} onChange={e => updateField(oferta, 'descricao', e.target.value)}>{oferta.descricao}</textarea>
                ) : (
                  <span style={{ maxWidth: 50}}>{oferta.descricao}</span>
                )}</Table.Cell>

                <Table.Cell>{oferta.editMode ? (
                  <input value={oferta.quantidade_entregas} style={{ maxWidth: 50}} onChange={e => updateField(oferta, 'quantidade_entregas', e.target.value)} />
                ) : (
                  <span>{oferta.quantidade_entregas}</span>
                )}</Table.Cell>

                <Table.Cell>{oferta.editMode ? (
                  <input value={oferta.preco_total} style={{ maxWidth: 50}} onChange={e => updateField(oferta, 'preco_total', e.target.value)} />
                ) : (
                  <span>{oferta.preco_total}</span>
                )}</Table.Cell>

                <Table.Cell>{oferta.editMode ? (
                  <input value={oferta.preco_total_com_desconto} style={{ maxWidth: 50}} onChange={e => updateField(oferta, 'preco_total_com_desconto', e.target.value)} />
                ) : (
                  <span>{oferta.preco_total_com_desconto}</span>
                )}</Table.Cell>

                <Table.Cell>
                  {oferta.editMode ? (
                    <>
                      <Button icon size="tiny" color="green" onClick={() => saveOferta(oferta)}>
                        <Icon name="save" />
                      </Button>
                      <Button icon size="tiny" color="grey" onClick={() => { toggleEditMode(oferta); fetchCombos() }}>
                        <Icon name="cancel" />
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button icon size="tiny" color="grey" onClick={() => toggleEditMode(oferta)}>
                        <Icon name="edit" />
                      </Button>                      
                    </>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>      
      </div>
    </LoggedInLayout>
  );
};

export default CombosPage;
