import { Table, Input, Button } from "semantic-ui-react";
import LoggedInLayout from "../layouts/LoggedInLayout";
import { useAxios } from "../hooks/useAxios";
import { useEffect, useState } from "react";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const PedidosPage = () => {
  const [pedidos, setPedidos] = useState([]);
  const [cliente, setCliente] = useState("");
  const [statusEntrega, setStatusEntrega] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [oferta, setOferta] = useState("");
  const [codigo, setCodigo] = useState("");

  const { axios } = useAxios();

  const fetchPedidos = () => {
    axios
      .get("/pedidos")
      .then((res) => {
        setPedidos(res.data);
        console.log(res.data);
      })
      .catch((error) => {
        alert(
          error?.response?.data?.mensagem ||
            "Falha ao tentar recuperar os pedidos. Por favor verifique sua conectividade e tente novamente."
        );
      });
  };

  useEffect(() => {
    fetchPedidos();
  }, []);

  function handleEntregue(event, pedido) {
    try {
      entregar(pedido.identrega, event.target.checked);
      const updatedPedidos = pedidos.map((p) =>
        p.identrega === pedido.identrega
          ? {
              ...p,
              status: event.target.checked,
              status_entrega: event.target.checked ? "ENTREGUE" : "PENDENTE",
            }
          : p
      );
      setPedidos(updatedPedidos);
    } catch (error) {}
  }

  const entregar = async (id, status) => {
    try {
      const response = await axios.post("/entregue", {
        id,
        status,
      });
    } catch (error) {
      //console.log(error);
      alert(error?.response?.data?.mensagem);
    }
  };

  const filteredPedidos = pedidos.filter((pedido) => {
    if (codigo && pedido.codigo !== codigo) {
      return false;
    }
    if (
      cliente &&
      pedido.nome_usuario.toLowerCase().indexOf(cliente.toLowerCase()) === -1
    ) {
      return false;
    }

    if (
      dataInicio &&
      moment(pedido.data_inicial_entrega).isBefore(dataInicio, "day")
    ) {
      return false;
    }

    if (dataFim && moment(pedido.data_final_entrega).isAfter(dataFim, "day")) {
      return false;
    }

    if (oferta && pedido.nome.toLowerCase() !== oferta.toLowerCase()) {
      return false;
    }

    if (
      statusEntrega &&
      pedido.status_entrega.toLowerCase() !== statusEntrega.toLowerCase()
    ) {
      return false;
    }

    return true;
  });

  const handleClienteChange = (event) => {
    setCliente(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusEntrega(event.target.value);
  };

  const handleOfertaChange = (event) => {
    setOferta(event.target.value);
  };

  const handleCodigoChange = (event) => {
    const codigoValue = event.target.value;
    setCodigo(codigoValue);
  };

  const listaClientes = Array.from(
    pedidos.reduce((nomes, item) => {
      nomes.add(item.nome_usuario);
      return nomes;
    }, new Set())
  );

  const listaOfertas = Array.from(
    pedidos.reduce((nomes, item) => {
      nomes.add(item.nome);
      return nomes;
    }, new Set())
  );

  const listaStatus = Array.from(
    pedidos.reduce((status, item) => {
      status.add(item.status_entrega);
      return status;
    }, new Set())
  );

  const handleSelectDataInicio = (event) => {
    if (!event) {
      setDataInicio("");
    } else {
      const newDate = new Date(event);
      setDataInicio(newDate);
    }
  };

  const handleSelectDataFim = (event) => {
    if (!event) {
      setDataFim("");
    } else {
      const newDate = new Date(event);
      setDataFim(newDate);
    }
  };

  const [pedidoImpressao, setPedidoImpressao] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (codigo) => {
    setModalOpen(true);
    setPedidoImpressao(filteredPedidos.find((item) => item.codigo === codigo));
    console.log();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const printContent = () => {
    const modalContent = document.getElementById("modal-content").innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Modal de Impressão</title>
          <style>
            @media print {
              /* Ocultar cabeçalho de impressão */
              @page {
                margin: 0;
              }
              body::before {
                content: "";
                display: none;
              }
              button {
                display: none;
              }              
              .printable-content {
                width: 311px;              
              }
            }
          </style>
        </head>
        <body>
          <div class="printable-content">
            ${modalContent}
          </div>
          <script>
            setTimeout(function() {
              window.print();
              window.close();
            }, 100);
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  return (
    <LoggedInLayout>
      <h1>Pedidos</h1>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          border: "1px solid #ccc",
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="cliente">Cliente:</label>
          <select
            id="cliente"
            value={cliente}
            onChange={handleClienteChange}
            style={{ height: 20, width: 200 }}
          >
            <option value=""></option>
            {listaClientes.map((nome) => {
              return (
                <option key={nome} value={nome}>
                  {nome}
                </option>
              );
            })}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="data-inicio">Data Inicial:</label>
          <DatePicker
            id="data-inicio"
            selected={dataInicio}
            onChange={handleSelectDataInicio}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="data-fim">Data Final:</label>
          <DatePicker
            id="data-fim"
            selected={dataFim}
            onChange={handleSelectDataFim}
            dateFormat="dd/MM/yyyy"
            style={{ marginLeft: "10px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="oferta">Oferta:</label>
          <select
            id="oferta"
            value={oferta}
            onChange={handleOfertaChange}
            style={{ height: 20, width: 200 }}
          >
            <option value=""></option>
            {listaOfertas.map((nome) => {
              return <option value={nome}>{nome}</option>;
            })}
          </select>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <label htmlFor="status">Status:</label>
          <select
            id="status"
            value={statusEntrega}
            onChange={handleStatusChange}
            style={{ height: 20, width: 200 }}
          >
            <option value=""></option>
            {listaStatus.map((status) => {
              return <option value={status}>{status}</option>;
            })}
          </select>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* ... Restante do código ... */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label htmlFor="codigo">Código:</label>
            <input
              type="number"
              id="codigo"
              value={codigo}
              onChange={handleCodigoChange}
              style={{ height: 20, width: 200 }}
            />
          </div>
        </div>
      </div>

      <Table compact celled definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Entregue</Table.HeaderCell>
            <Table.HeaderCell>Data Entrega</Table.HeaderCell>
            <Table.HeaderCell>Oferta</Table.HeaderCell>
            <Table.HeaderCell>Valor Total</Table.HeaderCell>
            <Table.HeaderCell>Dados de Entrega</Table.HeaderCell>
            <Table.HeaderCell>Código</Table.HeaderCell>
            <Table.HeaderCell>Imprimir</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredPedidos?.map((pedido) => {
            //console.log(pedido)
            return (
              <Table.Row key={pedido.idEntrega}>
                <Table.Cell>
                  <input
                    type="checkbox"
                    checked={pedido.status}
                    onChange={(event) => handleEntregue(event, pedido)}
                  />
                </Table.Cell>
                <Table.Cell style={{ whiteSpace: "nowrap" }}>
                  {moment(pedido.data_inicial_entrega).format(
                    "DD/MM/YYYY HH:mm"
                  ) +
                    " - " +
                    moment(pedido.data_final_entrega).format("HH:mm")}
                </Table.Cell>
                <Table.Cell>
                  <strong>{pedido.nome}</strong>
                  <br />
                  {pedido.descricao}
                </Table.Cell>
                <Table.Cell>
                  {Number(pedido.valor_total).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Table.Cell>
                <Table.Cell>
                  <Table.Cell>{pedido.nome_usuario}</Table.Cell>
                  <Table.Cell>
                    {pedido.rua}, {pedido.numero}, {pedido.complemento} -{" "}
                    {pedido.bairro} <br />
                    <strong>Tel: {pedido.telefone}</strong>
                  </Table.Cell>
                  <Table.Cell>Pedido realizado em </Table.Cell>
                  <Table.Cell>
                    {moment(pedido.data_pedido).format("DD/MM/YYYY HH:MM")}
                  </Table.Cell>
                </Table.Cell>
                <Table.Cell>{pedido.codigo}</Table.Cell>

                <Table.Cell>
                  <button onClick={() => openModal(pedido.codigo)}>
                    Imprimir
                  </button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>

        {modalOpen && (
          <div className="modal">
            <div className="modal-content" id="modal-content">
              <p>
                Restaurante Viasushi.
                <br />
                <br />
                Solicite o código de confirmação {pedidoImpressao.codigo} na
                hora da entrega.
                <br />
                <br />
                Dados do cliente:
                <br />
                Nome: {pedidoImpressao.nome_usuario}
                <br />
                Telefone: {pedidoImpressao.telefone}
                <br />
                Endereço: {pedidoImpressao.rua}, {pedidoImpressao.numero}
                <br />
                Bairro: {pedidoImpressao.bairro}
                <br />
                Complemento: {pedidoImpressao.complemento}
                git <br />
                Cidadde: Juiz de Fora
                <br />
                Cep: {pedidoImpressao.cep}
                <br />
                <br />
                Itens do pedido: {pedidoImpressao.nome}
                <br />
                <br />
                Data:{" "}
                {moment(pedidoImpressao.data_inicial_entrega).format(
                  "DD/MM/YYYY HH:mm"
                ) +
                  " - " +
                  moment(pedidoImpressao.data_final_entrega).format("HH:mm")}
              </p>
              <div className="button-container">
                <button onClick={printContent}>Imprimir</button>
                <button onClick={closeModal}>Fechar</button>
              </div>

              <style>
                {`
                      /* Estilo para o modal */
                      .modal {
                        display: flex;
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        justify-content: center;
                        align-items: center;                        
                      }

                      .modal-content {
                        background-color: #fff;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                        width: 311px;
                        max-height: 80vh;
                        overflow-y: auto;                     
                      }
                      .button-container {
                        display: flex;
                        gap: 10px; /* Define o espaçamento entre os botões */
                      }

                    `}
              </style>
            </div>
          </div>
        )}

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell>
              {filteredPedidos
                .reduce((acc, row) => acc + Number(row.valor_total), 0)
                .toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell />
            <Table.HeaderCell />
          </Table.Row>
        </Table.Footer>
      </Table>
    </LoggedInLayout>
  );
};

export default PedidosPage;
